import React from 'react';
import { Link } from 'gatsby';

import './sectionNavigate.scss';

interface SectionNavigateProps {
    next?:string;
    previous?:string;
}

const SectionNavigate:React.FC<SectionNavigateProps> = ({next, previous}) => {
    return (
        <div className="_sectionNavigate">
                <Link className="previous" to={previous}>
                    Anterior
                </Link>
                <Link className="next" to={next}>
                    Siguiente
                </Link>
        </div>
    )
}

export default SectionNavigate;