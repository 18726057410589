import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import MercadoLibreLogo from "../../../components/images/mercadolibre"
import SectionNavigate from '../../../components/widgets/sectionNavigate';
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import { IMG_Mercadolibre } from '../../../components/images/docs/mercadolibre';
import AmazonLogo from '../../../components/images/amazon';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyClavesDefault: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <AmazonLogo />
            </div>
            <h2>
                Facturación reembolso <br />
                <span className="small">(Nota de crédito)</span>
            </h2>
            <p>
                Desde la misma vista de facturación manual podrás emitir una nota de crédito, sin embargo, <b>es necesario que por lo menos uno de los pedidos del comprador haya sido cancelado para emitirla</b>.<br />
                De esta forma, solamente debes de preocuparte de las notas de crédito cuando es necesario. <b>Si un comprador solo obtuvo un solo pedido, es mejor cancelar la factura completamente.</b>
            </p>
            <ImageFooted explanation={`Emitir una factura de reembolso es el mismo proceso que la facturación manual, los campos ya están pre-llenados y solamente hay que emitirla.`}>
                <IMG_Mercadolibre file={`facturacionReembolso`} />
            </ImageFooted>

            <SectionNavigate next={`/ecommerce/amazon/facturacion-global`} previous={`/ecommerce/amazon/facturacion-manual`} />
        </div>
    </Layout>
)

export default ShopifyClavesDefault;