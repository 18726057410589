import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"

type FILE_OPTIONS = 'configGlobalAuto'|'misfacturasReporte'|'misfacturas'|'cancelarFact'|'productoEditMultipleSat'|'productoEditMultiple'|'productoEdit'|'productos'|'facturacionGlobal'|'facturacionReembolso'|'facturacionManual'|'autofactComplete'|'autofactAgain'|'autofactReg'|'ordenesLink'|'configAutofact'|'certificates'|'clavesPredet';

export const IMG_Mercadolibre: React.FC<{ file: FILE_OPTIONS }> = ({ file }) => {
  const data = useStaticQuery(graphql`
    query {
      certificates: file(relativePath: { eq: "docimages/mercadolibre_certificados.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      clavesPredet: file(relativePath: { eq: "docimages/mercadolibre_clavesPredet.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      configAutofact: file(relativePath: { eq: "docimages/mercadolibre_AutoFacturacion.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      ordenesLink: file(relativePath: { eq: "docimages/mercadolibre_ordenesLink.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      autofactReg: file(relativePath: { eq: "docimages/mercadolibre_autofact1.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      autofactAgain: file(relativePath: { eq: "docimages/mercadolibre_autofact2.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      autofactComplete: file(relativePath: { eq: "docimages/mercadolibre_autofact3.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      facturacionManual: file(relativePath: { eq: "docimages/mercadolibre_factmanual.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      facturacionReembolso: file(relativePath: { eq: "docimages/mercadolibre_notacredito.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      facturacionGlobal: file(relativePath: { eq: "docimages/mercadolibre_global.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      productos: file(relativePath: { eq: "docimages/mercadolibre_productos.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      productoEdit: file(relativePath: { eq: "docimages/mercadolibre_editProduct.png" }) {
        childImageSharp {
          fixed (width:650){
            ...GatsbyImageSharpFixed
          }
        }
      }
      productoEditMultiple: file(relativePath: { eq: "docimages/mercadolibre_productosMultiple.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      productoEditMultipleSat: file(relativePath: { eq: "docimages/mercadolibre_productSatEdit.png" }) {
        childImageSharp {
          fixed (width:650){
            ...GatsbyImageSharpFixed
          }
        }
      }
      cancelarFact: file(relativePath: { eq: "docimages/mercadolibre_cancelar.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      misfacturas: file(relativePath: { eq: "docimages/mercadolibre_misFacturas.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      misfacturasReporte: file(relativePath: { eq: "docimages/mercadolibre_reporteFact.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      configGlobalAuto: file(relativePath: { eq: "docimages/mercadolibre_globalconfig.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  if (file === 'certificates') {
    return (
      <Img
        fixed={data.certificates.childImageSharp.fixed}
        alt="Configurar certificados"
      />
    )
  } 
  else if (file === 'clavesPredet') {
    return (
      <Img
        fixed={data.clavesPredet.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'configAutofact') {
    return (
      <Img
        fixed={data.configAutofact.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'ordenesLink') {
    return (
      <Img
        fixed={data.ordenesLink.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'autofactReg') {
    return (
      <Img
        fixed={data.autofactReg.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'autofactAgain') {
    return (
      <Img
        fixed={data.autofactAgain.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'autofactComplete') {
    return (
      <Img
        fixed={data.autofactComplete.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'facturacionManual') {
    return (
      <Img
        fixed={data.facturacionManual.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'facturacionReembolso') {
    return (
      <Img
        fixed={data.facturacionReembolso.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'facturacionGlobal') {
    return (
      <Img
        fixed={data.facturacionGlobal.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'productos') {
    return (
      <Img
        fixed={data.productos.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'productoEdit') {
    return (
      <Img
        fixed={data.productoEdit.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'productoEditMultiple') {
    return (
      <Img
        fixed={data.productoEditMultiple.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'productoEditMultipleSat') {
    return (
      <Img
        fixed={data.productoEditMultipleSat.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'cancelarFact') {
    return (
      <Img
        fixed={data.cancelarFact.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'misfacturas') {
    return (
      <Img
        fixed={data.misfacturas.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'misfacturasReporte') {
    return (
      <Img
        fixed={data.misfacturasReporte.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'configGlobalAuto') {
    return (
      <Img
        fixed={data.configGlobalAuto.childImageSharp.fixed}
        alt="Configurar factura global"
      />
    )
  }
}
